.swiper {
  width: 100%;
  height: 45vh;

  :global(.swiper-pagination) {
    margin-top: -30px;
    position: relative;
  }
}

.swiper__child{
  height: 100%;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 90%;
  width: 40vh;
  overflow: hidden;
}

.swiper__logos {
  display: none;
}

@media (max-width: 450px) {
  .swiper {
    position: fixed;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100vh;

    :global(.swiper-pagination) {
      display: none;
    }
  }

  .slide {
    overflow: visible;
    height: 100vh;
    width: 100%;
    box-shadow: none;
  }

  .swiper__logos {
    display: block !important;
  }
}
