.container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: 35px;
  background-color: #c3fb00;
  top: 0px;
  z-index: 90;
}

.swiper {
  max-height: 35px;
}

.slide {
  width: 100%;
  max-height: 35px;
}

.msg {
  display: flex;
  justify-content: center;
  margin: 0px;
  margin-top: -5px;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 450px) {
  .container {
    position: absolute;
    width: 100%;
    max-width: 100%;
    max-height: 65px;
    background-color: #c3fb00;
    bottom: calc(0px + 40px) !important;
    top: revert;
    z-index: 90;
  }

  .swiper {
    max-height: 65px;
  }

  .slide {
    width: 100%;
    max-height: 65px;
  }

  .msg {
    display: flex;
    justify-content: center;
    margin: 0px;
    margin-top: -5px;
    font-size: 14px;
    text-align: center;
  }
}
