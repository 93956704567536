.container {
  max-width: none;
}

.swiper {
  width: 98vw;
  height: 5vh;
  padding: 0rem 1rem;

  justify-content: center;
  width: 80vw;
  display: flex;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 100%;
  width: 6%;
}

@media (max-width: 768px) {
  .swiper {
    padding: 0;
    width: 100%;
    height: 20vh;
    margin-bottom: 3rem;
  }

  .slide {
    overflow: visible;
    height: 10vh;
  }
}

@media (max-width: 450px) {
  .swiper {
    position: fixed;
    bottom: 60px;
    height: 10vh;
  }
  .slide {
    height: 100%;
    width: 20%;
    filter: invert(100%);
  }
}
