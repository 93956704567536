.container {
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

@media (max-width: 450px) {
  .container {
    display: block;
  }

  .main {
    display: block;
  }
}

.carrousel_banner {
  margin-left: 85px;
  margin-right: 85px; 
  width: calc(100% - 170px);
}


@media (max-width: 1025px) {
  .carrousel_banner {
    margin-left: 65px;
    margin-right: 65px; 
    width: calc(100% - 130px);
  }
}
