.container {
  position: fixed;
  width: 150vw;
  height: calc(100vh - 380px);
  padding: 0px;
  left: -25%;
}

.swiper {
  height: calc(100vh - 0px);
  bottom: 15vh;
}

.slide {
  width: 100%;
}
